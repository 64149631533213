.lightBtn {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;

  svg {
    path {
      fill: #212529;
      stroke: #212529;
    }
  }
}
