.adsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.createButton {
  margin-bottom: 20px;
  background-color: white;
  color: black;
}

.adItem {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.adPreview {
  margin-right: 20px;
}

.adBackground {
  width: 86px;
  height: 86px;
}

.gif {
  width: 86px;
  height: 86px;
}

.adInfo {}

.adLink {
  display: none;
}

.linkDisabled {
  pointer-events: none;
  cursor: default;
  color: #ccc;
}

.ant-btn {
  background-color: white !important;
  color: black !important;
}

