.dragItem {
  position: relative;
  transition: transform .3s;
}

.matched.vertical {
  transform: translateY(10%);
}
.matched.horizontal {
  transform: translateX(10%);
}


.active {
  opacity: 0.7;
}
.active:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 2;
}

.dragShadow {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.5;
  z-index: 2;
}
