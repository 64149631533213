.compareContentItem {
  display: flex;
  flex-direction: column;
  position: relative;
}

.error {
  position: absolute;
  right: 0;
  top: -15px;
}

.subItemTitle {
  font-weight: 600;
}

.compareContentItemHead {
  position: relative;
}

.compareContentItemExpand {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--color-main);
}