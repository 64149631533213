.logout {
  display: flex;
  align-items: center;
  padding: var(--space-1) var(--space-2);
  cursor: pointer;
  border-radius: 6px;
}
.logout:hover {
  background-color: var(--color-bg);
}

.menu {
  border-radius: 6px;
}
.menu :global(.anticon svg) {
  font-size: 15px;
  margin-right: var(--space-2);
}

.menuText {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}