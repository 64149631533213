.itemWrapper {
  padding: 0 var(--space-2) var(--space-2) 0;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  width: 33.33333%;
}
.itemWrapper:nth-child(3n) {
  padding-right: 0;
}

.item {
  width: 30rem;
}
.item:hover {
  background-color: #f5f5f5;
}

.itemContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--color-border);
  padding: var(--space-3) var(--space-4);
  min-width: 300px;
}
.itemContent :global(h5) {
  margin-bottom: 0 !important;
}

