.buffer {
  border-top: 1px solid var(--color-border);
}

.boolComponent {
  display: flex;
  align-content: center;
}
.boolComponent > span:first-child {
  margin-right: var(--space-2);
}

.componentWrapper {
  padding: var(--space-2) 0;
}

.field > *:last-child {
  padding-bottom: 0;
}
