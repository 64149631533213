@mixin xxs {
  @media screen and (max-width: 320px) {
    @content;
  }
}

@mixin xs {
  @media screen and (min-width: 321px) {
    @content;
  }
}
@mixin sm {
  @media screen and (min-width: 576px) {
    @content;
  }
}

@mixin md {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin lg {
  @media screen and (min-width: 992px) {
    @content;
  }
}

@mixin xl {
  @media screen and (min-width: 1200px) {
    @content;
  }
}
@mixin xxl {
  @media screen and (min-width: 1400px) {
    @content;
  }
}
