.subItem {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: var(--space-3) var(--space-4);
  border-top: 1px solid var(--color-border);
}

.subItem:not(:last-child) {
  margin-right: 1rem;

}

.subItem span {
  margin-right: 0.3rem;
}

