.enumChooser :global(h5) {
  margin-bottom: var(--space-4);
}
.enumChooser > .platformsItem:last-child {
  margin-bottom: 0;
}

.enumChooserItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-4);
  font-size: 14px;
  line-height: 18px;
}
