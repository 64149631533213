.sortScreenItem {
  margin-bottom: var(--space-3);
}

.sortScreenItemHeader {
  cursor: pointer;
  padding: var(--space-4);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sortScreenItemHeader > span {
  font-size: 16px;
  font-weight: 600;
}

.sortScreenItemOpened {
  padding-bottom: var(--space-1);
}