.itemReviewFooter {
  display: flex;
}
.itemReviewFooter :global(input) {
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
}

.title {
  font-size: 21px !important;
  line-height: 24px !important;
  font-weight: 500 !important;
}

.saved {
  font-size: 16px !important;
}

.saveBtn {
  color: var(--color-main);
  border: 1px solid var(--color-main);
}