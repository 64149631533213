.staffEdit {
    background: #FFFFFF;
}

.row {
  padding: var(--space-3) 0;
}

.staffEdit :global(.ant-card-bordered) {
    border: none !important;
}

.staffEdit :global(.ant-card-body) {
    padding: var(--space-4);
}

.staffEdit :global(.ant-input) {
    border-bottom: 1px solid #DCE6EC !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    width: 50% !important;
}
.staffEdit :global(.ant-input-disabled){
    color: #404040 !important;
    background-color: var(--color-light) !important;
    cursor: default !important;
}


.staffEdit :global(.ant-input:focus) {
    box-shadow: none !important;
}
.border,
.background{
    border: 1px solid #DCE6EC;
    border-radius: 3px;
    max-width: 312px !important;
    padding: 0;
    height: 53px;

}
.border :global(.ant-radio-wrapper),
.background :global(.ant-radio-wrapper){
    width: 100% !important;
    margin-right: 0 !important;
    padding: 14px  20px !important;
    height: 100%;
}

.background{
    background: #EEF4FE;
}

.title{
    max-width: 200px !important;
}