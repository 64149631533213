.uploaderActions {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.takeNewButton {
  position: relative;
  display: flex;
  align-items: center;
  .error {
    font-size: 12px;
    margin-left: 10px;
  }
}

.errorsLog {
  width: 500px;
  position: fixed !important;
  max-height: 700px;
  overflow-y: auto;
  right: 0;
  top: 0;
  z-index: 9999;
}

.errorsLogItem {
  & > *:first-child {
    font-weight: 600;
    margin-right: 20px;
  }
}

.progress {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  & :global(.error) {
    text-align: left;
  }
}