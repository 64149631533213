.submitGroup {
  display: flex;
  justify-content: space-between;
  padding: var(--space-4);
  margin: 0 calc(-1 * var(--space-4));
  border-top: 1px solid var(--color-border);
}
.submitGroup :global(.ant-btn) {
  min-width: 114px;
}

.mainActions > *:not(:last-child) {
  margin-right: var(--space-4);
}

.fixWidthBtn {
  width: 170px;
}

.deleteBtn {
  margin-left: var(--space-4);
}
