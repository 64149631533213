.variantInput {
  border-radius: 2px;
  border: 1px solid var(--color-border);
  width: 60px;
  padding: 0 var(--space-2) 0 var(--space-2);
  display: flex;
  justify-content: space-between;
  position: relative;
}
.variantInput::placeholder {
  color: var(--color-grey-dark);
}

.variantInput input {
  width: 100%;
  border: none;
  outline: none;
}

.counter {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  width: 18px;
  height: 100%;
}
.counter :global(.anticon) {
  font-size: 6px;
  color: var(--color-grey-dark);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  cursor: pointer;
  border-left: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
}
.counter :global(.anticon):last-child {
  border-bottom: none;
}
