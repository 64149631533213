.breadcrumbs {
  display: flex;
  align-items: center;
  color: var(--color-grey);
  font-size: 14px;
  line-height: 18px;
  margin-bottom: var(--space-3);
}
.breadcrumbs :global(.anticon) {
  margin-right: var(--space-1);
}

.breadcrumbsContent span:first-child {
  cursor: pointer;
}
.breadcrumbsContent span:last-child {

  margin-left: var(--space-1);
}

.active {
  color: var(--antd-primary-color);
}