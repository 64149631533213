.wrapper {
  position: relative;
  display: inline-flex;
  margin-top: var(--space-4);
}

.wrapper:hover .bigImage {
  display: flex;
}

.bigImage {
  position: absolute;
  width: 20rem;
  height: 20rem;
  top: 0;
  left: 13rem;
  display: none;
}
.bigImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
