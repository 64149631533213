.seoBlock {
  border: none !important;
  padding-bottom: 0 !important;
}

.approve {
  border: 1px solid var(--color-border);
  border-radius: 3px;
  padding: var(--space-2) var(--space-3);

  h5 {
    margin-bottom: 0 !important;
  }

  .approveAction {
    display: flex;
    column-gap: 16px;
    margin-top: var(--space-2) !important;
  }
}

.description {
  resize: none;
  height: 140px;
}

.sideContent {
  padding-top: 31px;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  position: relative;
}
.sideContent > * {
  margin-bottom: var(--space-2);
}

.approveTitle {
  position: absolute;
  right: 0;
  top: 0;
}
