.csvItem {
  user-select: none;
}

.compareItem {
  position: relative;
  display: flex;
  //flex-direction: column;
  margin-bottom: 0;
  & :global(.ant-checkbox-wrapper) {
    margin-left: 0 !important;
  }
}

.notice {
  position: absolute;
  top: -10px;
  right: 0;
  font-size: 10px;
  transform: translate(100%, 0);
  &.red {
    color: red;
  }
}

.compareElem {
  display: flex;
  position: relative;
  padding: 10px 0;
  //& > *:first-child {
  //  font-size: 10px;
  //  margin-right: 10px;
  //}
  &.chosen:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    background-color: rgba(104, 255, 126, 0.71);
  }
}

.itemTitle {
  font-size: 13px;
}

.success {
  color: green;
  padding: 0 10px;
}

.error {
  color: red;
}
