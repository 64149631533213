.sliderContainer {
  width: 80vw;
  padding-bottom: 30px;
}

.sliderContent {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}

.sliderItem {
  display: flex;
  height: 100%;
  align-items: center;
  width: 200px;
  max-width: 200px;
  user-select: none;
  cursor: grab;
  overflow: hidden;
}
.sliderItem :global(.ant-card-body) {
  width: 100%;
}

.sliderItemContent {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
}
.sliderItemContent input {
  width: 25px;
  height: 25px;
  border: 1px solid grey;
  margin-right: 10px;
}
.sliderItemContent input::-webkit-outer-spin-button,
.sliderItemContent input::-webkit-inner-spin-button  {
  -webkit-appearance: none;
  margin: 0;
}
.sliderItemContent input[type=number] {
  -moz-appearance: textfield;
}
.sliderItemContent h3 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0;
}

.dragItem {
  padding: 5px;
}