.glace{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 15px;
}
.item-glance{
    height: 86px;
    background: #FFFFFF;
    border: 1px solid #DCE6EC;
    width: 265px;
    margin-right: 24px;
    margin-bottom: 24px;
    padding: 9px;
}

.item-glance p {
    font-size: 14px;
    color: #000035;
    margin: 0;
}
.number{
    font-weight: normal;
    font-size: 32px;
}
