.drawer {
  width: 365px !important;
}
.drawer :global(.ant-drawer-title) {
  font-size: 21px;
}
.drawer :global(.ant-drawer-close) {
  top: 3px;
}
.drawer :global(.ant-drawer-header), .drawer :global(.ant-drawer-close) {
  padding: 32px 30px;
}