:root {
  /* Colors */
  --color-bg: #F3F5F6;
  --color-grey-light: #EEF4FE;
  --color-grey: #B7B8B8;
  --color-grey-dark: #7D7D7D;
  --color-tint: #eeeeee;
  --color-light: #ffffff;
  --color-text: #000035;
  --color-error: red;
  --color-orange: #FDB902;
  --color-secondary: #EEF4FE;
  --color-main: #0F5EF7;
  --color-success: #01b401;
  --color-border: #DCE6EC;
  --muar-light: rgba(255, 255, 255, 0.5);

  /* Spaces */
  --space-1: 6px;
  --space-2: 12px;
  --space-3: 18px;
  --space-4: 24px;
  --space-5: 30px;
  --space-8: 48px;
  --space-10: 4rem;

  /* Font */
  --font-size-xl: 1.6rem;
  --font-size-xxl: 1.8rem;

  /* Sizes */
  --image-preview-width: 4.5rem;
  --image-preview-fluid-width: 10rem;

  /* Radius */
  --radius-xs: 3px;

  /* Layers */
  --layer-upperest: 10000;

  /* Shadow */
  --shadow: 1px 1px 7px 1px rgba(0, 0, 0, .1);
  --shadow-y: 0 11px 12px -2px rgba(0, 0, 0, .1);
  --shadow-top: 0px -1px 0px #DCE6EC;

  /* Ant rewrite */
  --antd-primary-color: #0F5EF7;
}
