.prosCons {
  display: flex;
  justify-content: space-between;
}
.prosCons > * {
  width: calc(50% - 6px);
}

.prosConsList {
  padding: var(--space-1) var(--space-2);
  border: 1px solid var(--color-border);
  border-radius: 3px;
}
.prosConsList h6 {
  font-size: 14px;
  font-weight: 600;
}

.addBtn {
  color: var(--color-main);
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  padding: var(--space-3) 0 var(--space-1) 0 !important;
  height: initial !important;
}
.addBtn :global(.anticon) {
  font-size: 11px;
}

.input {
  font-size: 14px !important;
  font-weight: 500;
  border: none !important;
}

.deleteBtn {
  color: var(--color-grey) !important;
}
.deleteBtn :global(.anticon) {
  font-size: 11px;
}