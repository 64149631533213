.tableList {
  padding: var(--space-2) var(--space-4) 0 var(--space-4);
  background-color: #fff;
}
.tableList :global(.ant-table-cell) {
  font-size: 16px;
}
.tableList :global(thead .ant-table-cell) {
  padding: var(--space-1) var(--space-2);
  background-color: var(--color-grey);
  border: none;
}
.tableList :global(.ant-table-row) {
  cursor: pointer;
}

.disabledRow {
  opacity: 0.5;
}
