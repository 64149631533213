.reviewItem {
  padding: var(--space-3);
  border-radius: 3px;
  border: 1px solid var(--color-border);
  margin-top: var(--space-2);
  width: 526px;
}

.title {
  font-size: 21px !important;
  line-height: 24px !important;
  font-weight: 500 !important;
}

.description {
  font-size: 16px !important;
}

.reviewItemHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.reviewItemHead :global(.UI__icon) {
  color: var(--color-main);
}
.reviewItemHead :global(input) {
  display: none !important;
}
.reviewItemHead :global(input) {
  font-size: 21px !important;
  font-weight: 600 !important;
}
.reviewItemHead :global(textarea) {
  font-size: 16px !important;
}
.reviewItemHead :global(input::placeholder) {
  margin-left: var(--space-2) !important;
}
.reviewItemHead :global(textarea::placeholder) {
  margin-left: var(--space-2);
}

.addBtn {
  display: flex;
  align-items: center;
}
.addBtn :global(.anticon) {
  font-size: 17px;
}

.datePicker {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.datePickerTitle {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-main);
}

.calendarIcon {
  margin-left: var(--space-2);
}

.reviewsList {
  max-height: 800px;
  overflow-y: auto;
}

.listItem {
  cursor: pointer;
  padding: 10px;
  border-radius: 6px;
}

.listItem:hover {
  background: #eeeeee;
}
