.search {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    background-color: #fff;
    height: 74px;
    border: 1px solid var(--color-border);
    border-left: none;
    margin-left: 300px;
    width: calc(100% - 300px);
    padding: 0 43px 0 var(--space-5);
    z-index: 10;
}

.content {
  width: 100%;
  max-width: calc(100% - 300px);
  padding-top: 74px !important;
  background-color: var(--color-bg);
}
