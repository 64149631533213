.BuildInfoContainer {
  padding: 20px 25px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  font-size: 12px;
  width: 100%; 
}

.button {
  margin-bottom: 20px;
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  width: 120.5px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: #fff;
  background: #0F5EF7;
  border-color: #0F5EF7;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
}

.button:hover,
.button:focus {
  color: #fff;
  background: #40a9ff;
  border-color: #40a9ff;
}

.sideMenu {
  height: 100vh;
  min-width: 300px;
  border-right: 1px solid var(--color-border);
  max-height: 100vh;
}

.sideMenu > *:first-child {
  overflow-y: auto;
  min-width: 300px !important;
}

.sideMenu :global(.ant-layout-sider) {
  position: fixed;
  height: 100vh;
  background: #ffffff !important;
}

.sideMenu :global(.ant-menu-item::after) {
  border-right: none !important;
  border-left: 3px solid #0F5EF7;
  right: initial !important;
  left: 0;
}

.triggerLink {
  background-color: var(--color-light);
  height: 74px;
  border-bottom: 1px solid var(--color-border);
  border-right: 1px solid var(--color-border);
  margin-bottom: 16px;
}

.menuItem {
  padding: 16px 15px 16px 20px !important;
  margin: 0 !important;
  height: initial !important;
  line-height: 22px !important;
  font-size: 16px;
}

.menuItem :global(.ant-menu-item-icon) {
  font-size: 16px !important;
}

.subMenuItem :global(.ant-menu-submenu-title) {
  padding: 16px 15px 16px 20px !important;
  margin: 0 !important;
  height: initial !important;
  line-height: initial !important;
  font-size: 16px;
}

.subMenuItem :global(.anticon) {
  font-size: 16px !important;
}

.menuItem,
.subMenuItem {
  font-weight: 500;
}
