.success {
  color: var(--color-success);
}

.danger {
  color: var(--color-error);
}

.pointer {
  cursor: pointer;
}

.page {
  background-color: var(--color-grey);
  height: 100%;
  padding-bottom: var(--space-8);
}

.container {
  width: 100%;
  padding: var(--space-3) var(--space-5);
  margin: 0 auto;
}
.container.ma-0 {
  margin: 0;
}
.container-shrink {
  max-width: 1300px;
  width: 100%;
}

.icon {
  width: 2rem;
  height: 2rem;
  overflow: hidden;
}

span.error {
  text-align: center;
  width: 100%;
  color: red;
  padding: 0.3rem 0;
}

.pending {
  position: relative;
}
.pending:after {
  content: '';
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
}

.border-top{
  box-shadow: var(--shadow-top);
}

input, textarea {
  border-radius: 3px !important;
  border-color: var(--color-border) !important;
}
input::placeholder, textarea::placeholder {
  color: var(--color-grey) !important;
}

/* EDIT */
.editContent {
  padding-right: var(--space-5);
  width: calc(693px + var(--space-5));
  min-width: calc(693px + var(--space-5));
}

.editTitle {
  padding-bottom: var(--space-3);
  border-bottom: 1px solid var(--color-border);
  margin-bottom: var(--space-3);
}
.editTitle.last {
  border-bottom: none;
  margin-bottom: 0;
}
.editTitle.pb-0 {
  padding-bottom: 0;
}

.editSideContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 2;
}

.editSideContent__item {
  padding: var(--space-4) var(--space-3);
  border-radius: 3px;
  border: 1px solid #d9d9d9;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-3);
}
.editSideContent__item .mb-3 {
  margin-bottom: var(--space-3) !important;
}

.sideContentItem {
  border: 1px solid var(--color-border);
  border-radius: 3px;
  padding: var(--space-2) var(--space-3);
}

.sideContent {
  padding-top: 31px;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
}
.sideContent > * {
  width: 100%;
  margin-bottom: var(--space-3);
}
.sideContent > *:last-child {
  margin-bottom: 0;
}

  /* BTN */
.ant-btn.danger {
  border-color: var(--color-orange);
  color: var(--color-orange);
}

.divider {
  margin: var(--space-3) 0;
  height: 1px;
  width: 100%;
  background-color: var(--color-border);
}
