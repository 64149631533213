@import '../../../../assets/bs-breakpoints';

.grid {
  --gap: 5px;
  display: grid;
  column-gap: var(--gap);
  row-gap: var(--gap);

  @include xxs {
    grid-template-columns: repeat(3, 1fr);
  }
  @include xs {
    grid-template-columns: repeat(4, 1fr);
  }
  @include sm {
    grid-template-columns: repeat(6, 1fr);
  }
  @include lg {
    grid-template-columns: repeat(10, 1fr);
  }

  @include xxl {
    grid-template-columns: repeat(12, 1fr);
  }
}

.cell {
  display: flex;
  align-items: center;
  justify-content: center;
}
