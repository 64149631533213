.big-image {
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: 10px;
  position: relative;
  border: 1px solid grey;
  user-select: none;
  &__original {
    height: 100px;
    overflow-y: hidden;
    cursor: move;
  }
  &__shrink {
    width: 20%;
  }
}
.big-image img:not(.hover-picture) {
  width: 100%;
  top: 0;
  left: 0;
}
.big-image:hover .thumbnail-edit {
  display: flex;
}

.thumbnail-hover {
  display: none;
  position: absolute;
  top: -100%;
  right: 0;
  width: 400px;
  transform: translate(0, -50%);
  z-index: 10;
  box-shadow: -1px -1px 4px 2px rgba(0, 0, 0, 0.3);
}
.thumbnail-hover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnail-edit {
  width: 100%;
  margin-top: 10px;
  position: relative;
  img:first-child {
    width: 100%;
  }
  &__cropped {
    position: relative;
    cursor: pointer;
    &:hover {
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
  }
  &:hover {
    .hover-picture {
      display: flex;
    }
  }
}
.hover-picture {
  display: none;
  position: absolute;
  width: 400px;
  top: -100% !important;
  left: 120% !important;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.thumbnail-edit span {
  cursor: pointer;
  top: 0;
  left: 0;
}
.thumbnail-edit span:hover {
  text-decoration: underline;
}

.thumbnail {
  position: relative;
  border: 1px solid grey;
  &:not(:last-child) {
     border-right: 0;
   }
}
.thumbnail:hover .thumbnail-hover {
  display: block;
}
.thumbnail:hover .thumbnail-edit {
  display: flex;
}

.pictures__item {
  cursor: move;
  box-sizing: content-box;
  background: #FFFFFF;
  padding: 6px;
  border-radius: 2px;
  position: relative;
  transition: border-color 0.2s;
  width: 25%;
  margin-top: 20px;
  display: flex;
  .delete-picture {
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    font-size: 10px;
    display: none;
    z-index: 1;
  }
  &:hover {
    .delete-picture {
      display: block;
    }
  }
}

.pictures__item.current {
  opacity: 0;
  visibility: hidden;
}
