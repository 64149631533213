.seoHead {
  color: var(--color-grey-dark);
  font-size: 14px;
}

.capitalizeBtn {
  font-size: 10px !important;
  display: flex;
  align-items: center;
  padding: 0 4px !important;
  height: 20px !important;
  margin: var(--space-1) 0;
}
