.multiString {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid var(--color-border);
  padding: var(--space-1);
  height: 38px;
}

.tag {
  border: none !important;
  color: var(--color-main) !important;
  background-color: var(--color-secondary) !important;
  display: flex;
  align-items: center;
}
.tag :global(.anticon) {
  color: var(--color-main);
  margin-top: 1px;
}

.inputTag {
  border: none !important;
  background-color: transparent !important;
  color: var(--color-grey-dark) !important;
  font-size: 14px !important;
}