.actionColumn {
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  font-size: 30px;
  /*width: 100%;*/
  height: 100%;
  /*position: absolute;*/
  /*top: 0;*/
  /*left: 0;*/
  width: 110px;
}
