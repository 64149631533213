.table-analytic{
    width: 100%;
}
.table-analytic .ant-table-thead > tr > th {
    background: #EEF4FE;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 14px;
    color: #000035;
    border-bottom: 1px solid #DCE6EC;
    /*white-space: nowrap;*/
}
.table-analytic .ant-table-thead > tr > th:first-child{
    border-right: 1px solid #DCE6EC;
    padding:  5px 10px;
}

.table-analytic .ant-table-tbody > tr > td:first-child{
    background: #EEF4FE;
    border-right: 1px solid #DCE6EC;
    border-bottom: 1px solid #DCE6EC;
    font-weight: 600;
    font-size: 14px;
    color: #000035;
    padding: 19px 10px;

}
.table-analytic .ant-table{
    font-weight: normal;
    font-size: 14px;
    color: #000000;
}
.inside-cell{
    display: flex;
    align-items: center;
}
.inside-cell p{
    margin-bottom: 0;
    font-size: 12px;
    display: flex;
    align-items: center;
}
.inside-cell p .anticon{
    margin: 0 3px 0 6px;
}

