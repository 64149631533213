.label {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 1px dashed #40A9FF;
  border-radius: 2px;
  color: var(--color-grey-dark);
  cursor: pointer;
}
.label :global(.anticon) {
  font-size: 21px;
}

.inputText {
  font-size: 14px;
  margin-top: var(--space-1);
}

.actions {
  display: none;
  position: absolute;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.65;
  color: #ffffff;
  /*padding: var(--space-3);*/
}
.actions :global(.anticon) {
  font-size: 20px;
}

.previewContainer {
  padding: var(--space-1);
  border: 1px solid var(--color-border);
  border-radius: 2px;
  cursor: pointer;
}

.preview {
  position: relative;
  width: 100%;
  height: 100%;

}
.preview:hover .actions {
  display: flex;
}

.image {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

