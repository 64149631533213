.category {
  .icon {
    margin-left: 8px;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      color: var(--color-main);
    }
  }
}
