.treeTitle {
  white-space: nowrap;
}

.treeItem {
  padding: 10px 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
  & > *:first-child {
    font-size: 10px;
    margin-right: 10px;
  }
}