.filter {
  min-width: 170px;
  position: relative;
  padding: var(--space-1) var(--space-2);
  border: 1px solid var(--color-border);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 14px;
  color: var(--color-text);
}
.filter span:first-child {
  margin-right: var(--space-2);
}

.item {
  padding: var(--space-1) var(--space-3) !important;
}

.clear {
  padding: 0 0 0 var(--space-3);
}

.dropDownMenuFilter {
  border-radius: 6px !important;
}

.direction {
  font-size: 14px;
}
.direction :global(.anticon) {
  font-size: 15px !important;
  margin-right: var(--space-2);
}
.firstSortDirection {
  position: relative;
}
.firstSortDirection:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--color-border);
}

.activeDirection {
  color: var(--color-main) !important;
}
