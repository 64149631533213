.compareContentItem {
  display: flex;
  flex-direction: column;
}

.compareContent {
  display: flex;
  flex-direction: column;
  & > *:nth-child(2n) {
    background-color: var(--color-grey-light);
  }
}

.compareHeader {
  width: 100%;
  display: flex;
  & > * {
    width: 50%;
    display: flex;
    padding-left: 20px;
  }
}

.contentItem {
  display: flex;

}

.subItem {
  width: 50%;
  padding: 20px;
}

.subItemTitle {
  font-weight: 600;
}

.item {
  & > *:first-child {
    font-weight: 500;
    margin-right: 20px;
  }
  & > *:nth-child(2) {
    height: 70px;
    overflow-y: auto;
  }
}