.editor :global(.quill .ql-container) {
  min-height: 10rem !important;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-color: var(--color-border);
}
.editor :global(.ql-toolbar) {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-color: var(--color-border) !important;
}
.editor :global(.quill) {
  border-radius: 3px;
}
.editor :global(.ql-editor) {
  min-height: 10rem !important;
}
.editor :global(.ant-input) {
  min-height: calc(10rem + 42px);
}

.editor :global(.ql-btn-link:after ){
  content: "<btn />";
  display: block;
  width: 44px;
}

.editor :global(.btn-link) a {
  padding: 8px 15px;
  background: linear-gradient(180deg, #f65e5d 0%, #cb3427 100%);
  box-shadow: 0 4px 14px rgba(246, 94, 93, 0.4), inset 0 4px 4px rgba(255, 255, 255, 0.25);
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}

.footer {
  padding-top: var(--space-1);
  color: #404040;
  font-size: 14px;
  line-height: 18px;
}
