.container {
    display: flex;
    justify-content: space-between;
    margin-top: var(--space-1);
    background-color: #fff;
    padding: var(--space-2);
    padding-bottom: 60px;
}

.disabled {
    color: lightgrey;
}

.disabled :global(.UI__icon) path {
    fill: lightgrey;
}

.column {
    padding: var(--space-2);
}

.column > .category:not(:last-child) {
    margin-bottom: var(--space-3);
}

.category {
    width: 100%;
    background-color: white;
    transition: transform .3s;
    cursor: move;
    user-select: none;
    overflow: hidden;
    border-radius: 3px;
    border: 1px solid var(--color-border);
    padding: var(--space-2);
}

.title {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    max-width: calc(100% - 24px - 55px);
}

.title a {
    margin: 0 var(--space-1);
}

.titleTitle {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 90px;
}

.categoryHead {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.saveButton {
    position: fixed !important;
    right: 30px;
    bottom: 30px;

}

.dragOn {
    transform: translateY(30px);
}

.item {
    width: 100%;
    padding: 5px;
    background-color: var(--color-bg);
    margin-bottom: var(--space-1);
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    transition: transform .3s;
}

.item input {
    width: 40px;
    height: 24px;
    text-align: center;
    margin-right: var(--space-2);
    border: 1px solid var(--color-border);
    outline: none;
}

.itemTitle {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(100% - 10px - 40px - 24px);
}

.showItemsButton {
    transform-origin: center;
    color: var(--color-border) !important;
    transition: all 100ms linear;
}

.itemsContainer {
    width: 100%;
    display: none;
    padding-top: var(--space-2);
    min-height: 54px;
}

.showedItems .showItemsButton {
    transform: rotate(90deg);
}

.showedItems .itemsContainer {
    display: block;
}

.categoryCountContainer {
    display: flex;
    align-items: center;
    column-gap: 4px;
}

.categoryCountItem {
    width: 50px;
    margin-left: 8px;
}

.categoryCountItem input {
    max-width: 100%;
    height: 25px;
    width: 40px;
    box-sizing: border-box;
}

.categoryHeadMenu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.metaContainer {
    width: 100%;
    background-color: white;
    padding: 0 var(--space-4) var(--space-4) var(--space-4);
    display: flex;
    align-items: flex-end;
}

.metaContainer button {
    font-weight: 600;
}

.metaContainerItem {
    display: flex;
    flex-direction: column;
}

.metaContainerItem input {
    width: 123px;
    margin-right: var(--space-3);
}

@media (max-width: 1999px) {
    .titleTitle {
        max-width: 110px;
    }
}

@media (min-width: 2000px) {
    .titleTitle {
        max-width: 100%;
    }
}
