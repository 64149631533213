.item {
  position: relative;
  padding-bottom: 25px !important;

  .title {
    max-width: 100%;
  }

  .description {
    max-width: 100%;
  }

  .buttons {
    position: absolute;
    right: 0;
    bottom: 0;

    span {
      cursor: pointer;
    }
  }
}
