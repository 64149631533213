.placeholder {
  position: relative;
}
.placeholder img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar {
  border-radius: 50%;
  overflow: hidden;
}
