.labelInfoContainer {
    padding: 20px;
    border: 1px solid #ccc;
    width: 500px;
}

.createButton {
    margin-bottom: 16px;
}

.fileInput {
    display: none;
}

.uploadButton {
    margin-top: 10px;
    margin-bottom: 10px; /* Ensure spacing between the button and the preview icon */
}

.labelIcon, .iconPreview {
    width: 32px; /* Consistent icon size across the board */
    height: 32px;
    object-fit: cover;
}

.labelNameIconContainer {
    display: flex;
    align-items: center;
    padding: 8px 0; /* Better spacing in the list */
}

.labelIcon {
    margin-right: 12px; /* Adjusting space between icon and name */
}

.uploadButton + .iconPreview {
    margin-left: 10px; /* Adds padding to the left of the icon preview */
}