.globalSearch {
  border: none !important;
  padding: 0 !important;
  background-color: transparent !important;
}

.list {
  position: absolute;
  left: 0;
  top: calc(100% + 10px);
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid var(--color-border);
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px -1px 15px rgba(0, 0, 0, 0.05);

  display: flex;
}
.list > * {
  width: 20%;
}

.column {
  padding: var(--space-2) var(--space-3);
  max-height: 400px;
  overflow-y: auto;
}

.columnTitle {
  font-size: 16px;
  color: var(--color-grey-dark);
}

.closeIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
}

.itemTitle {
  font-size: 16px;
  color: var(--color-text);
  font-weight: 500;
  margin-left: var(--space-1);
  margin-bottom: 0;
}

.viewAllBtn {
  font-weight: 500;
  font-size: 15px;
}