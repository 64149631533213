.filterSet {
  background-color: var(--color-light);
}

.content {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.content > * {
  margin-right: var(--space-2);
}

.btn {
  width: 170px;
  height: 32px;
}
/* Search */
.searchField {
  height: 36px;
  border: none !important;
  border-bottom: 1px solid #DCE6EC !important;
  padding: 0 13px 0 13px !important;
  color: var(--color-grey-dark) !important;
  width: 380px !important;
  max-width: 100%;
}
.searchField input::placeholder {
  color: var(--color-grey-dark) !important;
}
/* Collapse */
.collapseItem :global(.ant-collapse-header) {
  font-size: 16px;
  font-weight: 600;
}
.collapseItem :global(.ant-collapse-content .ant-collapse-content-box) {
  display: flex;
  flex-direction: column;
  padding-top: 0 !important;
}
.collapseItem :global(.ant-collapse-content .ant-collapse-content-box > *) {
  margin-bottom: 10px;
}

.itemsList {
  max-height: 250px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.clearItems {
  cursor: pointer;
  color: #000;
}
