.mt-0 {
  margin-top: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.mr-0 {
  margin-right: 0;
}
.ml-0 {
  margin-left: 0;
}
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.ma-0 {
  margin: 0;
}
.pt-0 {
  padding-top: 0;
}
.pb-0 {
  padding-bottom: 0;
}
.pr-0 {
  padding-right: 0;
}
.pl-0 {
  padding-left: 0;
}
.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.px-0 {
  padding-left: 0;
  padding-right: 0;
}
.pa-0 {
  padding: 0;
}
.mt-1 {
  margin-top: var(--space-1);
}
.mb-1 {
  margin-bottom: var(--space-1);
}
.mr-1 {
  margin-right: var(--space-1);
}
.ml-1 {
  margin-left: var(--space-1);
}
.my-1 {
  margin-top: var(--space-1);
  margin-bottom: var(--space-1);
}
.mx-1 {
  margin-left: var(--space-1);
  margin-right: var(--space-1);
}
.ma-1 {
  margin: var(--space-1);
}
.pt-1 {
  padding-top: var(--space-1);
}
.pb-1 {
  padding-bottom: var(--space-1);
}
.pr-1 {
  padding-right: var(--space-1);
}
.pl-1 {
  padding-left: var(--space-1);
}
.py-1 {
  padding-top: var(--space-1);
  padding-bottom: var(--space-1);
}
.px-1 {
  padding-left: var(--space-1);
  padding-right: var(--space-1);
}
.pa-1 {
  padding: var(--space-1);
}
.mt-2 {
  margin-top: var(--space-2);
}
.mb-2 {
  margin-bottom: var(--space-2);
}
.mr-2 {
  margin-right: var(--space-2);
}
.ml-2 {
  margin-left: var(--space-2);
}
.my-2 {
  margin-top: var(--space-2);
  margin-bottom: var(--space-2);
}
.mx-2 {
  margin-left: var(--space-2);
  margin-right: var(--space-2);
}
.ma-2 {
  margin: var(--space-2);
}
.pt-2 {
  padding-top: var(--space-2);
}
.pb-2 {
  padding-bottom: var(--space-2);
}
.pr-2 {
  padding-right: var(--space-2);
}
.pl-2 {
  padding-left: var(--space-2);
}
.py-2 {
  padding-top: var(--space-2);
  padding-bottom: var(--space-2);
}
.px-2 {
  padding-left: var(--space-2);
  padding-right: var(--space-2);
}
.pa-2 {
  padding: var(--space-2);
}
.mt-3 {
  margin-top: var(--space-3);
}
.mb-3 {
  margin-bottom: var(--space-3);
}
.mr-3 {
  margin-right: var(--space-3);
}
.ml-3 {
  margin-left: var(--space-3);
}
.my-3 {
  margin-top: var(--space-3);
  margin-bottom: var(--space-3);
}
.mx-3 {
  margin-left: var(--space-3);
  margin-right: var(--space-3);
}
.ma-3 {
  margin: var(--space-3);
}
.pt-3 {
  padding-top: var(--space-3);
}
.pb-3 {
  padding-bottom: var(--space-3);
}
.pr-3 {
  padding-right: var(--space-3);
}
.pl-3 {
  padding-left: var(--space-3);
}
.py-3 {
  padding-top: var(--space-3);
  padding-bottom: var(--space-3);
}
.px-3 {
  padding-left: var(--space-3);
  padding-right: var(--space-3);
}
.pa-3 {
  padding: var(--space-3);
}
.mt-4 {
  margin-top: var(--space-4);
}
.mb-4 {
  margin-bottom: var(--space-4);
}
.mr-4 {
  margin-right: var(--space-4);
}
.ml-4 {
  margin-left: var(--space-4);
}
.my-4 {
  margin-top: var(--space-4);
  margin-bottom: var(--space-4);
}
.mx-4 {
  margin-left: var(--space-4);
  margin-right: var(--space-4);
}
.ma-4 {
  margin: var(--space-4);
}
.pt-4 {
  padding-top: var(--space-4);
}
.pb-4 {
  padding-bottom: var(--space-4);
}
.pr-4 {
  padding-right: var(--space-4);
}
.pl-4 {
  padding-left: var(--space-4);
}
.py-4 {
  padding-top: var(--space-4);
  padding-bottom: var(--space-4);
}
.px-4 {
  padding-left: var(--space-4);
  padding-right: var(--space-4);
}
.pa-4 {
  padding: var(--space-4);
}
.mt-5 {
  margin-top: var(--space-5);
}
.mb-5 {
  margin-bottom: var(--space-5);
}
.mr-5 {
  margin-right: var(--space-5);
}
.ml-5 {
  margin-left: var(--space-5);
}
.my-5 {
  margin-top: var(--space-5);
  margin-bottom: var(--space-5);
}
.mx-5 {
  margin-left: var(--space-5);
  margin-right: var(--space-5);
}
.ma-5 {
  margin: var(--space-5);
}
.pt-5 {
  padding-top: var(--space-5);
}
.pb-5 {
  padding-bottom: var(--space-5);
}
.pr-5 {
  padding-right: var(--space-5);
}
.pl-5 {
  padding-left: var(--space-5);
}
.py-5 {
  padding-top: var(--space-5);
  padding-bottom: var(--space-5);
}
.px-5 {
  padding-left: var(--space-5);
  padding-right: var(--space-5);
}
.pa-5 {
  padding: var(--space-5);
}
.mt-8 {
  margin-top: var(--space-8);
}
.mb-8 {
  margin-bottom: var(--space-8);
}
.mr-8 {
  margin-right: var(--space-8);
}
.ml-8 {
  margin-left: var(--space-8);
}
.my-8 {
  margin-top: var(--space-8);
  margin-bottom: var(--space-8);
}
.mx-8 {
  margin-left: var(--space-8);
  margin-right: var(--space-8);
}
.ma-8 {
  margin: var(--space-8);
}
.pt-8 {
  padding-top: var(--space-8);
}
.pb-8 {
  padding-bottom: var(--space-8);
}
.pr-8 {
  padding-right: var(--space-8);
}
.pl-8 {
  padding-left: var(--space-8);
}
.py-8 {
  padding-top: var(--space-8);
  padding-bottom: var(--space-8);
}
.px-8 {
  padding-left: var(--space-8);
  padding-right: var(--space-8);
}
.pa-8 {
  padding: var(--space-8);
}
.mt-10 {
  margin-top: var(--space-10);
}
.mb-10 {
  margin-bottom: var(--space-10);
}
.mr-10 {
  margin-right: var(--space-10);
}
.ml-10 {
  margin-left: var(--space-10);
}
.my-10 {
  margin-top: var(--space-10);
  margin-bottom: var(--space-10);
}
.mx-10 {
  margin-left: var(--space-10);
  margin-right: var(--space-10);
}
.ma-10 {
  margin: var(--space-10);
}
.pt-10 {
  padding-top: var(--space-10);
}
.pb-10 {
  padding-bottom: var(--space-10);
}
.pr-10 {
  padding-right: var(--space-10);
}
.pl-10 {
  padding-left: var(--space-10);
}
.py-10 {
  padding-top: var(--space-10);
  padding-bottom: var(--space-10);
}
.px-10 {
  padding-left: var(--space-10);
  padding-right: var(--space-10);
}
.pa-10 {
  padding: var(--space-10);
}

