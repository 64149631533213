.analytics-page{
    background: var(--color-light);
    width: calc(100% - 40px);
    margin: 20px auto;
    padding: 20px 24px;
}
.cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.card-table{
    border: 1px solid #DCE6EC;
    width: 49%;
    margin-bottom: 24px !important;
}

.card-doughnut {
    width: 49%;
}

.card-table .ant-card-head{
    background: #312ED1;
    height: 56px;
    font-weight: 600;
    font-size: 21px;
    color: #FFFFFF;
}

.ant-card-head-title{
    display: flex !important;
    padding: 0 !important;
    align-items: center;
    height: 56px;
}
.card-table  .ant-card-body{
    padding: 0 0 20px 0;
    display: flex;
    align-items: center;
    flex-direction: column;

}
.statistics-data{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
}
.statistics-data span{
    font-size: 16px;
    line-height: 22px;
    color: #000035;
    font-weight: normal;
}

.square{
    border-radius: 3px;
    width: 22px;
    height: 22px;
    margin:0 10px 0 24px;

}
